<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CRow>
          <CCol sm="12" class="text-center">
            <act-meter-filter :users="users"
                          :rows="rows"
                          v-on:set-period="setPeriod"
                          v-on:get-data="getData"
                          v-on:reset-data="resetData"/>
          </CCol>
        </CRow>
        <Pagination
          v-if="countRows>0"
          :countRows="countRows"
          :currentPage="currentPage"
          :perPage="10"
          v-on:set-page="setPage"
        ></Pagination>
        <TableAct
          :items="items"
          :fields="fields"
          :countRows="countRows"
          v-on:show-document="showDocument"
          hover
          striped
          border
          small
          fixed
          :caption="'Поверки  '+$store.getters.worker.label"
        />
        <Pagination
          v-if="countRows>0"
          :countRows="countRows"
          :currentPage="currentPage"
          :perPage="10"
          v-on:set-page="setPage"
        ></Pagination>
      </CCol>
    </CRow>
    <meter-modal v-show="show_document"
                 @hide-document="show_document=false"
                 :data="currentRow"
                 v-if="currentRow"
    />
    />
  </div>
</template>

<script>
import TableAct from '../base/TableAct.vue'
import ActMeterFilter from '../base/ActMeterFilter.vue'
import Pagination from '../base/Paginations.vue'
import {ADDRESS} from "../../backserver";

const axios = require("axios");

export default {
  name: 'Acts',
  components: { TableAct, Pagination, ActMeterFilter },
  data() {
    return {
      fields: [
        {key: 'number_act', label: 'Номер св-ва'},
        {key: 'date', label: 'Передан'},
        {key: 'created_at1', label: 'Создан'},
        {key: 'miowner', label: 'Клиент'},
        {key: 'siType', label: 'Тип'},
        {key: 'waterType', label: 'Вода'},
        {key: 'address', label: 'Примечание'},
        {key: 'regNumber', label: 'Рег. №'},
        {key: 'serialNumber', label: 'Заводской №'},
        {key: 'checkMethod', label: 'Методика поверки'},
        {key: 'nextTest', label: 'След. поверка'},
        {key: 'actions', label: 'Действия'}
      ],
      items: [],
      worker: {value: null, label: ''},
      currentRow: {},
      countRows: 0,
      currentPage: 1,
      perPage: 10,
      startDate: null,
      endDate: null,
      number_act: null,
      show_document: false,
    }
  },
  created() {
    this.getData();
    // if (this.$store.getters.worker)
    //   this.worker = this.$store.getters.worker;
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    rows() {
      return this.items.length;
    },
    users: function () {
      let options = [
        {value: this.$store.getters.user.id, label: this.$store.getters.user.name},
      ];
      if (this.$store.getters.user.slave_users) {
        this.$store.getters.user.slave_users.forEach((item) => {
          if (item.slave)
            options.push({value: item.slave_id, label: item.slave.name})
        });
      }
      return options;
    },
  },
  watch: {
    worker(val) {
      let data = this.users.filter(item => item.value===val);
      let worker = data.length===1 ? data[0] : {value: null, label: null};
      this.$store.dispatch('SET_WORKER', worker);
      if (val)
        this.getData();
    },
    currentPage() {
      this.getData();
    },
  },
  methods: {
    getData(user, number) {
      if (this.$store.getters.worker.value!==null) {
        axios.post(`/api/get-meters/${this.$store.getters.worker.value}`,
          {
            currentPage: this.currentPage,
            perPage: this.perPage,
            startDate: this.startDate,
            endDate: this.endDate,
            number: number
          })
          .then((resp) => {
            this.countRows = resp.data.count;
            this.items = resp.data.data;
          });
      }
    },
    resetData(user) {
      this.number_act = null;
      this.getData(user);
    },
    setPeriod(startDate, endDate) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.currentPage = 1;
      this.getData(this.user);
    },
    showJpg(item) {
      // console.log(item);
      this.currentRow = item;
      this.show_document = true;
    },
    loadPdf(item) {
      window.open(`${ADDRESS}/data/act/pdf?id=${item.number_act}&pin=${item.pin}`, '_blank')
    },
    setPage(val) {
      this.currentPage = val;
    },
    showDocument(item) {
      this.currentRow = item;
      this.show_document = !this.show_document;
    }
  }
}
</script>
